.main-general-info-wrapper {
  width: 715px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1331px) {
    width: 700px;
  }
  @media (max-width: 1024px) {
    margin-top: -11px;
    width: 100%;
  }
}
.main-container {
  // background-image: url(/images/desctop-header.png);
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 35px;
  border-radius: 12px;
  @media (max-width: 1024px) {
    width: 100%;
    border-radius: 0;
  }
}
.main-container-bcg {
  background: rgba(106, 106, 106, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 12px;
  padding: 20px 50px;
  width: 615px;
  margin: 20px auto;
  position: relative;
  @media (max-width: 740px) {
    margin: 30px 20px 10px 20px;
    padding: 20px;
    width: auto;
  }
}
.main-container-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  color: #333333;
  margin-bottom: 25px;
  margin-top: 5px;
}
.main-container-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  // color: #ffffff;
  margin-bottom: 15px;
}
.main-container-author {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.main-container-btn {
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin: 50px auto 50px;
  }
  @media (max-width: 499px) {
    margin: 30px auto 20px;
  }
}
.main-button {
  background: #6288e8;
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 8px;
  margin-bottom: 12px;
  &:hover {
    background: #5272c5;
  }
  @media (max-width: 1024px) {
    width: 350px;
  }
  @media (max-width: 499px) {
    width: 280px;
  }
}
.main-btn {
  background: #f0f0f0;
  border: 1px solid #6288e8;
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 8px;
  &:hover {
    background: #5272c5;
  }
  @media (max-width: 1024px) {
    width: 350px;
  }
  @media (max-width: 499px) {
    width: 280px;
  }
}
.main-btn:not(:last-child) {
  margin-bottom: 12px;
}
.main-btn:last-child {
  margin: 0;
}
.button-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
}
.btn-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #16161d;
}
.statistic-info-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    width: 728px;
    margin: 0 auto;
  }
  @media (max-width: 740px) {
    padding: 0 15px;
    width: 100%;
    margin: 0;
    flex-direction: column;
  }
}
.main-container-title-mobile {
  display: none;
  @media (max-width: 1024px) {
    display: block;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    margin-left: 80px;
    margin-top: 30px;
    color: #ffffff;
    text-shadow: 0 0 3px black;
    position: relative;
    z-index: 1;
  }
  @media (max-width: 740px) {
    margin: 15px 0 0 20px;
  }
}
.main-continer-image {
  height: auto;
  width: 100%;
  object-fit: cover;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 740px) {
    height: 100%;
  }
}
.mobile-primary-text {
  display: none;
  @media (max-width: 1024px) {
    display: block;
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
    color: #5a87ef;
  }
}
.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
}
.primary-text {
  color: #5a87ef;
  cursor: pointer;
  @media (max-width: 1024px) {
    display: none;
  }
}