.admin-block-wrapper {
  position: relative;
}
.search-block {
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.21);
  max-height: 300px;
  overflow: auto;
  display: none;
  margin-top: -15px;
  position: absolute;
  z-index: 3;
}
.search-user-wrapper {
  padding: 8px 20px;
  border-radius: 4px;
  background-color: #fff;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.17px;
  line-height: 21px;
  cursor: pointer;
  &:hover {
    background-color: #f4f7fe;
  }
}
.search-user-name {
  font-weight: 500;
  font-size: 18px;
}
.search-user-name-header {
  font-size: 18px;
  display: inline-block;
  margin: 5px 0 15px;
  font-weight: 500;
}
.search-user-number {
  font-weight: 500;
  font-size: 18px;
  color: grey;
  margin-left: 10px;
}
.search-user-number-header {
  font-size: 18px;
  color: grey;
  margin-left: 15px;
}
.search-user-name-header-wrapper {
  margin-bottom: 20px;
}
.admin-role-input-wrapper {
  width: 100%;
}
